import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-utils',
  templateUrl: './utils.component.html',
})
export class UtilsComponent {
  @Input() params: { type: string, class?: string } = { type: '' };
  constructor() { 
  }
}
