import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsComponent } from './utils.component';


@NgModule({
  declarations: [UtilsComponent],
  imports: [
    CommonModule
  ],
  exports: [UtilsComponent]
})
export class UtilsModule { }