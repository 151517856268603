<button *ngIf="params.type === 'buttonLoading'" type="button" [class]="params.class" disabled="">
  <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
    viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
    </path>
  </svg>
  Cargando...
</button>


<svg *ngIf="params.type === 'iconAlert'" class="h-5 w-5 text-red-500" viewBox="0 0 20 20" fill="currentColor"
  aria-hidden="true">
  <path fill-rule="evenodd"
    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5A.75.75 0 0110 5zm0 10a1 1 0 100-2 1 1 0 000 2z"
    clip-rule="evenodd" />
</svg>


<div *ngIf="params.type === 'skeletonTable'" role="status"
  class="min-w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700">
  <div class="flex items-center justify-between">
    <div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>

    <div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>

    <div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>

    <div>
      <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
      <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>
  </div>
  <div class="flex items-center justify-between pt-4">
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
  </div>
  <div class="flex items-center justify-between pt-4">
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
  </div>
  <div class="flex items-center justify-between pt-4">
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
  </div>
</div>
